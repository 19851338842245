import React from 'react';
import DataTable from 'react-data-table-component';
import { CardFooter, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Loading from '../Loader/Loading';

export default function CustomDataTable({
  previousPage,
  nowPage,
  nextPage,
  pagination,
  headers,
  data,
  loading,
  action,
  customStyles,
}) {
  return (
    <>
      <DataTable
        progressPending={loading}
        progressComponent={<Loading color="blue" />}
        columns={headers}
        data={data}
        onRowClicked={action ? action : () => {}}
        customStyles={customStyles ? customStyles : {}}
        noDataComponent={'Nenhum registro encontrado'}
      />

      {(previousPage || nextPage) && pagination && (
        <CardFooter>
          <nav className="clearfix" aria-label="...">
            <div>
              {pagination.totalResult > 0 && <p className="float-left">Total de páginas: {pagination.totalPages}</p>}
            </div>
            <div className="float-right">
              <Pagination className="pagination  mb-0" listClassName="float-right mb-0" style={{ flexWrap: 'wrap' }}>
                {(nowPage.page || nowPage.PAGE) !== 1 && (
                  <PaginationItem className="float-right">
                    <PaginationLink onClick={(e) => previousPage.onClick()} tabIndex="-1">
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Anterior</span>
                    </PaginationLink>
                  </PaginationItem>
                )}

                {nowPage && (
                  <PaginationItem>
                    <PaginationLink>{nowPage.page || nowPage.PAGE}</PaginationLink>
                  </PaginationItem>
                )}
                {(nowPage.page || nowPage.PAGE) !== pagination.totalPages && pagination.totalPages && (
                  <PaginationItem>
                    <PaginationLink onClick={(e) => nextPage.onClick()}>
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Próximo</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </div>
          </nav>
        </CardFooter>
      )}
    </>
  );
}
