import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { formatCPF, formatCNPJ, formatDateBR, isCpf } from '../../../../utils/index.js';
import { Button } from 'reactstrap';
import ModalReasonLimit from './modais/ModalReasonLimit/ModalReasonLimit.jsx';
import Loading from '../../../../components/Loader/Loading.jsx';
import StatusContainer from '../../../../components/StatusContainer/StatusContainer.js';
import CustomDataTable from '../../../../components/DataTable/DataTable.jsx';

function RequestLimit({ search, loading, limits, pagination, getValues }) {
  const [modalReason, setModalReason] = useState({ open: false, limit: {}, success: false, title: '' });

  useEffect(() => {
    search(getValues());
  }, []);

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        {loading ? (
          <Loading color="#11cdef" />
        ) : (
          <CustomDataTable
            loading={loading}
            pagination={pagination}
            previousPage={{ onClick: () => search({ PAGE: pagination.previousPage }) }}
            nowPage={{ page: pagination.page, onClick: () => search({ PAGE: pagination.page }) }}
            nextPage={{ onClick: () => search({ PAGE: pagination.nextPage }) }}
            data={limits}
            headers={[
              {
                name: 'Data',
                selector: (limit) => formatDateBR(limit.DTHR_SOLICITACAO),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'Nome',
                selector: (limit) => limit.NOME_SOLICITANTE[0].toUpperCase() + limit.NOME_SOLICITANTE.substring(1),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'CPF/CNPJ',
                selector: (limit) =>
                  limit.CPFCNPJ_SOLICITANTE &&
                  (isCpf(limit.CPFCNPJ_SOLICITANTE)
                    ? formatCPF(limit.CPFCNPJ_SOLICITANTE)
                    : formatCNPJ(limit.CPFCNPJ_SOLICITANTE)),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'TIPO LIMITE',
                selector: (limit) => limit.TIPO_LIMITE,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'TIPO TURNO',
                selector: (limit) => limit.TIPO_TURNO,
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'VALOR ATUAL',
                selector: (limit) =>
                  limit.VALOR_ATUAL
                    ? limit.VALOR_ATUAL.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    : '',
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'VALOR SOLICITADO',
                selector: (limit) =>
                  limit.VALOR_SOLICITADO.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'Parceiro',
                selector: (limit) => (
                  <StatusContainer code={limit.TIPO_USUARIO.toUpperCase()} description={limit.TIPO_USUARIO} />
                ),
                style: { justifyContent: 'start' },
                sortable: true,
              },
              {
                name: 'AÇÕES',
                selector: (limit) => (
                  <Box>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        setModalReason({ open: true, limit: limit, success: false, title: 'Motivo da Reprovação' })
                      }
                    >
                      Reprovar
                    </Button>
                    <Button
                      color="success"
                      size="sm"
                      onClick={() =>
                        setModalReason({ open: true, limit: limit, success: true, title: 'Motivo da Aprovação' })
                      }
                    >
                      Aprovar
                    </Button>
                  </Box>
                ),
                style: { justifyContent: 'start' },
                sortable: true,
              },
            ]}
            customStyles={{
              cells: {
                headCells: {
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                },
              },
            }}
          />
        )}
      </div>
      <ModalReasonLimit
        open={modalReason.open}
        title={modalReason.title}
        search={search}
        toggle={() => setModalReason({ open: false })}
        limit={modalReason.limit}
        success={modalReason.success}
      />
    </>
  );
}

export default RequestLimit;