import React, { useState } from 'react';
import { finishUser } from '../../../store/user/actions';
import { connect } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import { Box, CircularProgress, MenuItem, TextField } from '@mui/material';
import ModalMensagemSemReload from '../../../views/Transaction/ModalMensagemSemReload';

const ModalJustificativa = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;
  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [conta, setConta] = useState('');
  const [loading, setLoading] = useState(false);

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;
  const userLogin = localStorage.getItem('CPFCNPJ');

  const params = {
    CPFCNPJ_USUARIO: userLogin,
    CONTA: conta,
    MOTIVO: mensagem,
  };
  const handle = (target) => {
    setMensagem(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    setLoading(true);
    if (mensagem == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
    } else if (mensagem != '' && user.STATUS == 7) {
      props.finishUser(params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>Encerramento Conta</ModalHeader>
      <FormGroup>
        <ModalBody>
          <Box>
            {user.CONTA_BACEN && user.CONTA_BACEN.length > 0 && (
              <Box>
                <p>Conta a encerrar</p>
                <TextField
                  select
                  required
                  label="Conta"
                  name="CONTA"
                  size='small'
                  sx={{minWidth: "10rem"}}
                  onChange={({ target: { value } }) => setConta(value)}
                >

                  {user.CONTA_BACEN.map((conta) => {
                    if (!conta.DT_ENCERRAMENTO) {
                      return (
                        <MenuItem key={conta.UUID} value={conta.UUID}>
                          {conta.CONTA}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </Box>
            )}
          </Box>
          <Box>
            <span>Motivo do encerramento da conta</span>
            <Input placeholder="Descreva..." size="sm" onChange={(e) => handle(e.target.value)} required />
            {alert()}
          </Box>
        </ModalBody>
      </FormGroup>
      <ModalFooter>
        {loading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={() => toggle()} block>
                Cancelar
              </Button>
              <Button color="success" size="sm" onClick={() => click()} block>
                Confirmar
              </Button>
          </div>
        )}
      </ModalFooter>

      <ModalMensagemSemReload
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={() => {
          setModalMensagem(false);
          toggle();
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  finishUser,
})(ModalJustificativa);
