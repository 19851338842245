import React, { useState, useEffect } from 'react';
import '../../assets/vendor/nucleo/css/nucleo.css';
import './style.css';
import '../../assets/vendor/nucleo/css/nucleo.css';

import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { listarCoban, listarCartaoByUUID } from '../../store/coban/actions';
import { renderTaxasUsuario } from '../../components/Taxes/renderTaxas';
import ModalSaldo from '../../components/Modais/Cliente/ModalSaldo';
import ModalAlteraConta from '../../components/Modais/Contas/ModalAlteraConta';
import ModalBloquear from '../../components/Modais/Bloquear/ModalBloquear';
import ModalJustificativa from '../../components/Modais/Justificativa/Justificativa';
import ModalQRCODE from '../../components/Modais/ModalQRCODE/ModalQRCODE';
import ModalAlteraCoban from './ModalAlteraCoban';
import { pegarRiscoUsuario } from '../../store/user/actions';
import ModalMensagem from '../Transaction/ModalMensagem';
import ModalAprovaUser from './ModalAlteraCoban';
import ModalSenha from '../../components/Modais/Confirmar/ModalSenha';
import ShippingFormModal from '../Shipping/ShippingModalUser';
import AnimatedLoading from '../../components/Loader/AnimatedLoading';
import {
  Confirmacao as ModalConfirmacao,
  ModalTrocaSenha,
  VincularCartaoSwap as ModalVincularCartaoSwap,
} from './components/modals/index';
import { Box, Tabs, Tab } from '@mui/material';
import {
  ContactEmergency,
  AccountBalanceWallet,
  CreditCard,
  SettingsRounded,
  CreditScore,
  CurrencyExchange,
} from '@mui/icons-material';
import ModalCardUser from './components/modals/components/ModalCardUser/ModalCardUser';
import ModalVincularProduto from './components/modals/components/ModalVincularProduto/ModalVincularProduto';
import ModalStatusUsuario from './components/modals/ModalStatusUsuario';
import CardBankAccount from './CardBankAccount';
import ConfigUser from './ConfigUser';
import PersonalInformationsUser from './PersonalInformationsUser';
import Cards from './Cards';
import CardsAccount from './CardsAccount';
import Taxs from './components/Taxs/Taxs';
import ModalBloquearSaldo from '../../components/Modais/Cliente/ModalBloquearSaldo';

export default function FormUserNew(props) {
  const { user, cartaoContaDigital, risco, allFields, modalSolicitarCartaoNominal } = useSelector(
    (state) => state.user,
  );
  const { CARTOES, SALDO } = useSelector((state) => state.coban);
  const allCards = { ...CARTOES };
  const saldoGeral = { ...SALDO };
  const dispatch = useDispatch();
  const [infoPessoaisRedux] = useState({
    nome: user.NOME,
    email: user.EMAIL,
    cpf_cnpj: user.CPFCNPJ,
    nasc_fund: user.NASCIMENTO,
    telefone: user.TELEFONE,
    endereco: user.ENDERECO,
    bairro: user.BAIRRO,
    cidade: user.CIDADE,
    numero: user.NUMERO,
    uf: user.UF,
    cep: user.CEP,
    complemento: user.COMPLEMENTO,
    digits_variable: user.CNPJMATRIZ && user.CNPJMATRIZ.length == 14 ? user.CNPJMATRIZ.substr(11, 14) : '',
    matricula: user.MATRICULA,
    empresa: user.EMPRESATRABALHA,
    nome_coban: user.NOMECOBAN,
    conta: user.CONTA,
    agencia: user.AGENCIA,
    nome_mae: user.NOME_MAE,
    StatusSolicitacaoCartao: user.STATUS_CONTA_PARCEIRO,
    data_encerramento: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_ENCERRAMENTO : null,
    data_abertura: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_INSERT : null,
    cartaoContaDigital: [],
    riscoPLD: user.RISCO_PLD ? user.RISCO_PLD : null,
    conveniada: user.CONVENIADA,
    tipo_conta: user.TIPO_CONTA,
    status_usuario: user.STATUS ? user.STATUS : null,
    id_tipo_usuario: user.ID_TIPO_USUARIO ? user.ID_TIPO_USUARIO : null,
    tipo_conta: user.TIPO_CONTA ? user.TIPO_CONTA : 1,
  });

  const [informacoesPessoais, setInformacoesPessoais] = useState({
    id_user: user.ID,
    nome: user.NOME,
    email: user.EMAIL,
    cpf_cnpj: user.CPFCNPJ,
    nasc_fund: user.NASCIMENTO,
    telefone: user.TELEFONE,
    endereco: user.ENDERECO,
    bairro: user.BAIRRO,
    cidade: user.CIDADE,
    numero: user.NUMERO,
    uf: user.UF,
    cep: user.CEP,
    complemento: user.COMPLEMENTO,
    digits_variable: user.CNPJMATRIZ && user.CNPJMATRIZ.length == 14 ? user.CNPJMATRIZ.substr(11, 14) : '',
    matricula: user.MATRICULA,
    empresa: user.EMPRESATRABALHA,
    nome_coban: user.NOMECOBAN,
    conta: user.CONTA,
    conta_bacen: user.CONTA_BACEN,
    agencia: user.AGENCIA,
    nome_mae: user.NOME_MAE,
    StatusSolicitacaoCartao: user.STATUS_CONTA_PARCEIRO,
    status_cartao_cc: 'Cartão Não Solicitado',
    data_encerramento: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_ENCERRAMENTO : null,
    data_abertura: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].DT_INSERT : null,
    cartaoContaDigital: [],
    alteraCliente: true,
    modalJustificativa: false,
    alert: false,
    riscoPLD: user.RISCO_PLD ? user.RISCO_PLD : null,
    conveniada: user.CONVENIADA,
    status_usuario: user.STATUS ? user.STATUS : null,
    id_tipo_usuario: user.ID_TIPO_USUARIO ? user.ID_TIPO_USUARIO : null,
    tipo_conta: user.TIPO_CONTA,
  });
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalSenhaUnica, setModalSenhaUnica] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [modalSaldo, setModalSaldo] = useState(false);
  const [modalBloquearSaldo, setModalBloquearSaldo] = useState(false);
  const [sucesso, setSucesso] = useState('');
  const [modalAlterarCoban, setModalAlterarCoban] = useState({ open: false, title: '' });
  const [infoCard, setInfoCard] = useState({
    open: false,
    cartao: {},
  });
  const [openAccordionTaxes, setOpenAccordionTaxes] = useState(false);
  const [cartao, setCartao] = useState({});
  const [tipo, setTipo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [nextFunction, setNextFunction] = useState('');
  const [abreAlerta, setAbreAlerta] = useState(false);
  const [operation, setOperation] = useState('');
  const [aprovaUser, setAprovaUser] = useState(false);
  const [openDocument, setOpenDocument] = useState({});
  const [modalVincularCartaoSwap, setModalVincularCartaoSwap] = useState(false);
  const [idSwap, setIdSwap] = useState('');
  const [modalSenha, setModalSenha] = useState(false);
  const [trocaSenha, setTrocaSenha] = useState(false);
  const [modalVincularProduto, setModalVincularProduto] = useState({ open: false, PRODUTOS: '' });
  const [idCartao, setIdCartao] = useState('');
  const [modalDoc, setModalDoc] = useState(false);
  const [modalQRCODE, setModalQrCode] = useState(false);
  const [modalAlteraConta, setModalAlteraConta] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [modalJustificativa, setModalJustificativa] = useState(false);
  const [modalStatusUsuario, setModalStatusUsuario] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [reloadProdutos, setReloadProdutos] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [card, setCard] = useState('');
  const [filter, setFilter] = useState({
    limit: 10,
    search: '',
    page: 1,
    Tipo: '',
  });
  const [tabNumber, setTabNumber] = useState(0);

  let { page } = filter;

  const alterAlert = () => setInformacoesPessoais((prev) => ({ ...prev, alert: !prev.alert }));

  const openModalMessageAction = (res, tipo) => {
    const { mensagem, retorno } = res.data;

    res.status && res.status != 200 ? setSucesso(false) : setSucesso(true);

    setMensagem(mensagem);
    setTipo(tipo);
    setModalMensagem(true);
  };

  useEffect(() => {
    dispatch(listarCoban());
    dispatch(pegarRiscoUsuario());
    setInformacoesPessoais((prev) => ({
      ...prev,
      cartaoContaDigital: [...informacoesPessoais.cartaoContaDigital, cartaoContaDigital],
    }));
  }, []);

  useEffect(() => {
    dispatch(listarCoban());
    dispatch(pegarRiscoUsuario());
    setInformacoesPessoais((prev) => ({
      ...prev,
      cartaoContaDigital: [...informacoesPessoais.cartaoContaDigital, cartaoContaDigital],
    }));
  }, [reloadUser]);

  useEffect(() => {
    dispatch(listarCartaoByUUID(user.UUID, props.filterCards));
  }, [props.reloadCards, reloadProdutos]);

  const components = [
    <PersonalInformationsUser
      accept={props.accept}
      informacoesPessoais={informacoesPessoais}
      risco={risco}
      alterAlert={alterAlert}
      infoPessoaisRedux={infoPessoaisRedux}
      renderTaxasUsuario={renderTaxasUsuario}
      setInformacoesPessoais={setInformacoesPessoais}
      setOpenDocument={setOpenDocument}
      openDocument={openDocument}
      user={user}
      openAccordionTaxes={openAccordionTaxes}
      setOpenAccordionTaxes={setOpenAccordionTaxes}
      abreAlerta={abreAlerta}
      setAbreAlerta={setAbreAlerta}
      setModalSenhaUnica={setModalSenhaUnica}
      modalSenhaUnica={modalSenhaUnica}
      setOperation={setOperation}
    />,
    <CardBankAccount
      user={user}
      setModalSenhaUnica={setModalSenhaUnica}
      modalSenhaUnica={modalSenhaUnica}
      infoPessoaisRedux={infoPessoaisRedux}
      setOperation={setOperation}
      informacoesPessoais={informacoesPessoais}
    />,
    <CardsAccount informacoesPessoais={informacoesPessoais} />,

    <Cards
      allCards={allCards}
      saldoGeral={saldoGeral}
      filter={filter}
      setFilter={setFilter}
      setCard={setCard}
      setInfoCard={setInfoCard}
      user={user}
      setReloadProdutos={setReloadProdutos}
      reloadProdutos={reloadProdutos}
      renderTaxasUsuario={renderTaxasUsuario}
      openAccordionTaxes={openAccordionTaxes}
      setOpenAccordionTaxes={setOpenAccordionTaxes}
      setModalSenhaUnica={setModalSenhaUnica}
      setOperation={setOperation}
      informacoesPessoais={informacoesPessoais}
      filterCards={props.filterCards}
      forceReload={props.forceReload}
      setFilterCards={props.setFilterCards}
    />,

    <Taxs user={user} setModalSenhaUnica={setModalSenhaUnica} setOperation={setOperation} />,

    <ConfigUser
      setOpenAccordionTaxes={setOpenAccordionTaxes}
      setState={setInformacoesPessoais}
      user={user}
      setModalSenhaUnica={setModalSenhaUnica}
      informacoesPessoais={informacoesPessoais}
      setInformacoesPessoais={setInformacoesPessoais}
      setOperation={setOperation}
      infoPessoaisRedux={infoPessoaisRedux}
      setTabNumber={setTabNumber}
    />,
  ];

  return (
    <>
      <div style={{ width: '95%', margin: '2rem auto 0 auto', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* modal para quando salvar alguma alteração do usuário, pedir para digitar a senha de acesso */}
        <Box>
          <Tabs
            value={tabNumber}
            onChange={(event, number) => informacoesPessoais.alteraCliente && setTabNumber(number)}
          >
            <Tab
              icon={<ContactEmergency />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Informações Pessoais"
            />

            <Tab
              icon={<AccountBalanceWallet />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Conta bancária"
            />
            <Tab
              icon={<CreditScore />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Conta cartão"
            />
            <Tab
              icon={<CreditCard />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Cartões"
            />

            <Tab
              icon={<CurrencyExchange />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Taxas"
            />

            <Tab
              icon={<SettingsRounded />}
              sx={{
                bgcolor: 'white',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginRight: 0.5,
              }}
              label="Configurações"
            />
          </Tabs>

          {components[tabNumber]}
        </Box>
      </div>

      <ModalVincularProduto
        modal={modalVincularProduto.open}
        produtos={modalVincularProduto.PRODUTOS}
        cpfcnpj={infoPessoaisRedux.cpf_cnpj}
        setReloadProdutos={setReloadProdutos}
        reloadProdutos={reloadProdutos}
        toggle={(e) => setModalVincularProduto(false)}
        idSwap={idSwap}
        idCartao={idCartao}
      />

      <ModalSenha
        modal={modalSenhaUnica}
        setModalQrCode={setModalQrCode}
        close={() => setModalSenhaUnica(false)}
        allFields={allFields}
        nextFunction={nextFunction}
        operation={operation}
        dados={informacoesPessoais.dados}
        setModalDoc={setModalDoc}
        setModalAlterarCoban={setModalAlterarCoban}
        setModalAlteraConta={setModalAlteraConta}
        setModalBlock={setModalBlock}
        setModalSaldo={setModalSaldo}
        setModalJustificativa={setModalJustificativa}
        setModalStatusUsuario={setModalStatusUsuario}
        setModalBloquearSaldo={setModalBloquearSaldo}
      />

      <Alert isOpen={visible} color="warning" toggle={() => setVisible(false)}>
        {messageAlert}
      </Alert>

      <ModalBloquear
        isOpen={modalBlock}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => setModalBlock(!modalBlock)}
      />
      <ModalAlteraCoban
        modal={modalAlterarCoban.open}
        toggle={() => setModalAlterarCoban({ open: false, title: '' })}
        cpfCliente={infoPessoaisRedux.cpf_cnpj}
        title={modalAlteraConta.title}
      />
      <ModalCardUser
        info={infoCard}
        toggle={() => setInfoCard((prev) => ({ ...prev, open: false }))}
        setModalSenha={setModalSenha}
        setIdSwap={setIdSwap}
        setIdCartao={setIdCartao}
        isConvened={user.CONVENIADA == 'S' ? true : false}
        setCartao={setCartao}
        setModalConfirmacao={setModalConfirmacao}
        modalConfirmacao={modalConfirmacao}
        setModalVincularProduto={setModalVincularProduto}
        forceReload={props.forceReload}
      />
      <ModalAlteraConta
        modal={modalAlteraConta}
        toggle={() => {
          setModalAlteraConta(false);
        }}
        data={informacoesPessoais.dados}
        option={informacoesPessoais.options2}
      />
      <ModalSaldo
        toggle={() => {
          setModalSenhaUnica(false);
          setModalSaldo(false);
        }}
        modal={modalSaldo}
      />
      
      <ModalBloquearSaldo
        toggle={() => {
          setModalSenhaUnica(false);
          setModalBloquearSaldo(false);
        }}
        modal={modalBloquearSaldo}
      />
      <ShippingFormModal modal={modalDoc} sendDoc={true} close={() => setModalDoc(false)} />
      <ModalQRCODE
        isOpen={modalQRCODE}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => setModalQrCode(!modalQRCODE)}
      />
      <ModalJustificativa
        isOpen={modalJustificativa}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={() => {
          setModalSenhaUnica(false);
          setModalJustificativa(!modalJustificativa);
        }}
      />
      <ModalVincularCartaoSwap
        modal={modalVincularCartaoSwap}
        toggle={(e) => setModalVincularCartaoSwap(false)}
        idSwap={idSwap}
      />
      <ModalSenha
        modal={modalSenha}
        close={() => setModalSenha(false)}
        operation={'trocaSenha'}
        modalTrocaSenha={(e) => setTrocaSenha(true)}
      />
      <ModalAprovaUser
        modal={aprovaUser}
        toggle={() => setAprovaUser(!aprovaUser)}
        title="Selecione o coban para o usuário"
        option="approve"
      />
      <ModalTrocaSenha isOpen={trocaSenha} id={idSwap} toggle={(e) => setTrocaSenha(false)} />

      <ModalConfirmacao
        user={user}
        modalConfirmacao={modalConfirmacao}
        state={cartao}
        setCartao={setCartao}
        setLoaded={setLoaded}
        toggleConfirmacaoCallback={(el) => setModalConfirmacao(!modalConfirmacao)}
        openModalMessageActionCallback={(el) => openModalMessageAction(el)}
      />
      <AnimatedLoading isActive={loaded} />
      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        tipo={tipo}
        toggle={() => setModalMensagem(false)}
      />
      <ModalStatusUsuario
        isOpen={modalStatusUsuario}
        toggle={() => {
          setModalStatusUsuario(false);
          setModalSenhaUnica(false);
        }}
        setReloadUser={setReloadUser}
        reloadUser={reloadUser}
        informacoesPessoais={informacoesPessoais}
      />
    </>
  );
}
