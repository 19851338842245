import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';
import valueEmpty from '../../utils/valueEmpty.js';
import { centerItem } from '../../utils/constants.js';

// reactstrap components
import { Row } from 'reactstrap';
import {
  getTotalReport,
} from '../../store/transactions/actions.js';
import Loading from '../../components/Loader/Loading.jsx';
import FilterReport from '../../components/Filter/FilterReport/FilterReport.jsx';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';

const Report = () => {
  const dispatch = useDispatch();
  const [filtro, setFiltro] = useState({page: 1});

  const { relatorio_parametrizado: {pagination, historico, loading}, filtroReport } = useSelector(state => state.transactions);

  useMemo(() => {
    dispatch(getTotalReport(pagination));
  }, []);

  async function setPagination (type = 'atual', pageClick) {
    let { page } = filtro;

    switch (type) {
      case 'prev':
        page = pagination.prevPage;
        break;
      case 'next':
        page = pagination.nextPage;
        break;
      default:
        if (pageClick) {
          page = pageClick;
        }
        break;
    }
    await setFiltro({ page });
    dispatch(getTotalReport({
      ...filtroReport,
      page
  }));
  };

  function renderTableSaque() {
    const { page } = filtro;

    const DESCRICAOSTATUS = (res) => {
      let description = '';
      {
        res == 1 && (description = 'AGUARDANDO');
      }
      {
        res == 3 && (description = 'CANCELADO');
      }
      {
        res == 4 && (description = 'VENCIDO');
      }
      {
        res == 2 && (description = 'APROVADO');
      }
      {
        res == 5 && (description = 'EM ANÁLISE');
      }
      return description;
    };

    return (
      <>
        <Table
          id="table_relatorio"
          headers={[
            {
              info: 'Data/Hora',
              style: { textAlign: 'center' },
            },
            {
              info: 'NOME REMETENTE',
              style: { textAlign: 'left' },
            },
            {
              info: 'CPF/CNPJ REMETENTE',
              style: { textAlign: 'left' },
            },
            {
              info: 'NOME DESTINATÁRIO',
              style: { textAlign: 'left' },
            },
            {
              info: 'CPF/CNPJ DESTINATÁRIO',
              style: { textAlign: 'left' },
            },
            {
              info: 'Parceiro',
              style: { textAlign: 'left' },
            },
            {
              info: 'Tipo',
              style: { textAlign: 'left' },
            },
            {
              info: 'Status',
              style: { textAlign: 'center' },
            },
            {
              info: 'Valor Operação',
              style: { textAlign: 'center' },
            },
            {
              info: 'Taxa',
              style: { textAlign: 'center' },
            },
            {
              info: 'Valor pago',
              style: { textAlign: 'center' },
            },
          ]}
          data={
            historico &&
            historico.map((log) => {
              return {
                data: [
                  {
                    info: valueEmpty(log.DATAHORA),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(log.NOME_ORIGEM),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.DOCUMENTO_ORIGEM === null
                        ? '-----'
                        : log.DOCUMENTO_ORIGEM && log.DOCUMENTO_ORIGEM.length == 11
                        ? formatCPF(log.DOCUMENTO_ORIGEM)
                        : formatCNPJ(log.DOCUMENTO_ORIGEM),
                    ),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(log.NOME_DESTINO),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.DOCUMENTO_DESTINO === null
                        ? '-----'
                        : log.DOCUMENTO_DESTINO && log.DOCUMENTO_DESTINO.length == 11
                        ? formatCPF(log.DOCUMENTO_DESTINO)
                        : formatCNPJ(log.DOCUMENTO_DESTINO),
                    ),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: log.TIPO_USUARIO ? (
                      <StatusContainer
                        code={log.TIPO_USUARIO.toUpperCase()}
                        description={log.TIPO_USUARIO}
                      />
                    ) : (
                      '-----'
                    ),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(log.DESCRICAO),
                    style: { textAlign: 'left' },
                  },
                  {
                    info: valueEmpty(
                      log.STATUSTRANSACAO && (
                        <StatusContainer
                          code={log.STATUSTRANSACAO}
                          description={DESCRICAOSTATUS(log.STATUSTRANSACAO)}
                        />
                      ),
                    ),
                    style: { textAlign: 'center' },
                  },
                  {
                    info: valueEmpty(
                      log.VALOR_OPERACAO && (
                        <StatusContainerValue code={log.TIPO} description={log.VALOR_OPERACAO} />
                      ),
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    info: valueEmpty(
                      log.SPREAD && <StatusContainerValue code={log.TIPO} description={log.SPREAD} />,
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                  {
                    info: valueEmpty(
                      log.VALOR_TRANSACAO && (
                        <StatusContainerValue code={log.TIPO} description={log.VALOR_TRANSACAO} />
                      ),
                    ),
                    style: {
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                    },
                  },
                ],
              };
            })
          }
          pagination={pagination}
          previousPage={{ onClick: () => setPagination('prev') }}
          nowPage={{ page, onClick: setPagination }}
          nextPage={{ onClick: () => setPagination('next') }}
        />
      </>
    );
  }
  return (
      <div>
        <CustomContainer>
          <Row>
            <div className="col">
              <FilterReport identificador={'saque'} />
              {loading ? (
                <Loading color="blue" />
              ) : historico ? (
                renderTableSaque()
              ) : (
                <h3 style={centerItem}>Nenhum registro encontrado</h3>
              )}
            </div>
          </Row>
        </CustomContainer>
      </div>
    );
}

export default Report;