import { CONTABIL_ALL, CONTABIL_LIST } from './types';
import api from '../../service/api';
import dayjs from 'dayjs';

export const listarContabil = (filtro = { limit: 5, page: 1 }, flagDispatch, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: CONTABIL_ALL, payload: { loading_contabil: true } });
    try {
      const { status, data } = await api.post(`analise/contabil`, filtro);
      dispatch({
        type: flagDispatch ? CONTABIL_ALL : CONTABIL_LIST,
        payload: {
          loading_contabil: false,
          pagination: data.VALORES.pagination,
          listAccounting: data.VALORES.data,
          RECEITA: data.contabil.RECEITA,
          RESULTADO: data.contabil.RESULTADO,
          DESPESA: data.contabil.DESPESA,
        },
      });

      callback(true);
    } catch (error) {
      const { payload } = error.response.data;
      dispatch({ type: CONTABIL_ALL, payload: { loading_user: false } });
      callback(false);
    }
  };
};

export const modelo4111XML = (conta, saldo, data, tipo_remessa, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: CONTABIL_ALL,
      payload: {
        loading_accounting: true,
      },
    });

    try {
      const response = await api
        .post(
          '/xml/modelo-4111',
          {
            CONTAS: [
              {
                CONTA: conta,
                SALDO: saldo,
              },
            ],
            DATA: data,
            TIPO_REMESSA: tipo_remessa,
          },
          { responseType: 'blob' },
        )
        .then(function (response) {
          dispatch({
            type: CONTABIL_ALL,
            payload: { loading_accounting: false },
          });
          let fileName = `modelo4111_${dayjs().format('DD/MM/YYYY')}.xml`;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: 'text/xml',
              }),
              fileName,
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: 'text/xml',
              }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        });
    } catch (error) {
      dispatch({
        type: CONTABIL_ALL,
        payload: {
          loading_accounting: false,
        },
      });
      callback(error);
    }
  };
};

export const getSaldoContaDigital = (res) => {
  return async (dispatch) => {
    dispatch({
      type: CONTABIL_ALL,
      payload: { loading_accounting: true },
    });
    try {
      const response = await api.get(`conta-digital/saldo/dia?DATA=${res}`);
      const { status, data } = response;

      dispatch({
        type: CONTABIL_ALL,
        payload: {
          loading_accounting: false,
          saldoContaDigital: data.saldo,
        },
      });
    } catch (error) {
      dispatch({
        type: CONTABIL_ALL,
        payload: { loading_accounting: false },
      });
    }
  };
};

export const listarTransacaoDia = (filtro = { limit: 20, page: 1 }, flagDispatch) => {
  return async (dispatch) => {
    dispatch({
      type: CONTABIL_ALL,
      payload: { loading_accounting: true },
    });

    try {
      const response = await api.post(`transacao/dia`, filtro);
      const { status, data } = response;
      dispatch({
        type: flagDispatch ? CONTABIL_ALL : CONTABIL_ALL,
        payload: {
          loading_accounting: false,
          movimentacaoDia: {
            listMovimentacaoDia: data.TRANSACOES || [],
            moviventacoesDia: data,
            pagination: data.PAGINATION || {},
          },
        },
      });
    } catch (error) {
      dispatch({
        type: CONTABIL_ALL,
        payload: { loading_accounting: false },
      });
    }
  };
};

export const movimentacaoDashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTABIL_ALL,
      payload: { loading_accounting: true },
    });

    try {
      const response = await api.get(`/dashboard`);
      const { status, data } = response;
      dispatch({
        type: CONTABIL_ALL,
        payload: {
          loading_accounting: false,
          movimentacaoDashboard: data,
        },
      });
    } catch (error) {
      dispatch({
        type: CONTABIL_ALL,
        payload: { loading_accounting: false },
      });
    }
  };
};

export const getSaques = () => {
  return async (dispatch) => {
    dispatch({
      type: CONTABIL_ALL,
      payload: { loading_accounting: true },
    });

    try {
      const response = await api.get(`/analise/range-data`);
      const { status, data } = response;
      dispatch({
        type: CONTABIL_ALL,
        payload: {
          loading_accounting: false,
          saques: {
            dadoSaques: data,
            transacaoDebito: data.debitos,
            transacaoCredito: data.creditos,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: CONTABIL_ALL,
        payload: { loading_accounting: false },
      });
    }
  };
};
