import React, { useEffect, useState } from 'react';
import {
  Col,
  Modal,
  Row,
  ModalHeader,
  FormGroup,
  InputGroupAddon,
  Input,
  Label,
  Form,
  ModalFooter,
  Button,
  ModalBody,
} from 'reactstrap';
import { getServicosDigitais, alteraServicosDigitais } from '../services/fetchsApi';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';

function ServicosDigitais(props) {
  const [produtos, setProdutos] = useState([]);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const { modal, toggle, id } = props;
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    setProdutos([]);
    const fetchData = async () => {
      const data = await getServicosDigitais(id);
      setProdutos(data.data.PRODUTOS);
    };
    fetchData();
  }, [id]);

  async function changeDigitalServices() {
    let data = { ID_COBAN: '', PRODUTOS: [] };
    data.PRODUTOS = produtos;
    data.ID_COBAN = id;
    const res = await alteraServicosDigitais(data);
    setSucesso(!res.error);
    setMensagem(res.mensagem);
    setModalMensagem(true);
  }

  function mudarAtivado(e) {
    let arrayAuxiliar = [...produtos];
    arrayAuxiliar[e.target.name - 1].ATIVO = !arrayAuxiliar[e.target.name - 1].ATIVO;
    setProdutos(arrayAuxiliar);
  }

  const produto = produtos.map((produto, index) => (
    <FormGroup key={produto.NOME} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
      <h4>{produto.NOME} </h4>

      <InputGroupAddon addonType="prepend"></InputGroupAddon>

      <Label check>
        <Input type="checkbox" defaultChecked={produto.ATIVO} onChange={mudarAtivado} name={produto.IDPRODUTO} /> Ativo
      </Label>
    </FormGroup>
  ));
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalMensagem
        modal={modalMensagem}
        toggle={() => {
          setModalMensagem(!modalMensagem);
        }}
        sucesso={sucesso}
        mensagem={mensagem}
      />
      <ModalHeader toggle={toggle}>
        <h3>Definir taxas para serviços digitais</h3>
      </ModalHeader>
      <ModalBody>{produto}</ModalBody>
      <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
        <Button color="success" size="sm" block onClick={() => changeDigitalServices()}>
          {' '}
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ServicosDigitais;
