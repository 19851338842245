import React from 'react';
import CustomTable from '../../Table/Table';
import { formatCNPJ, formatCPF } from '../../../utils';
import formatMoney from '../../../utils/formatMoneyBRL';
import { accontAndDigitMask } from '../../../utils/functions';
import { ContainerStatus } from '../../StatusContainer/StatusContainer.styles';

function TableRechargePixAccount({ data }) {

   function chooseScheduledBadge(status) {
     if (!status) {
         return <ContainerStatus background={'#D1EFDB'} color={'#16718f'}>
           Processando
         </ContainerStatus>;
     }
     if (status == 'waiting') {
       return (
         <ContainerStatus background={'#D1EFDB'} color={'#16718f'}>
           Pendente
         </ContainerStatus>
       );

     }
      
     if (status == 'processing') {
         return (
         <ContainerStatus background={'#f0eb68'} color={'#ffc247'}>
           Em processamento
         </ContainerStatus>
         )
     }
     if (status == 'approved') {
           return (
             <ContainerStatus background={'#D1EFDB'} color={'#13B934'}>
               Aprovado
             </ContainerStatus>
           );
     }
     if (status == 'reproved') {
    return (
      <ContainerStatus background={'#D7D9DB'} color={'#D7D9DB'}>
        Reprovado
      </ContainerStatus>
    );
     }

     if (status == 'canceled') {
    return (
      <ContainerStatus background={'#D7D9DB'} color={'#D7D9DB'}>
        Cancelado
      </ContainerStatus>
    );
     }
   }

  
  return (
    <CustomTable
      style={{ marginTop: '.5rem', width: '100%' }}
      size="sm"
      headers={[
        {
          info: 'CPF colaborador',
          style: { textAlign: 'center' },
        },
        {
          info: 'Nome colaborador',
          style: { textAlign: 'center' },
        },

        {
          info: 'Conta bancária',
          style: { textAlign: 'center' },
        },
        {
          info: 'Status',
          style: { textAlign: 'center' },
        },
        {
          info: 'Valor',
          style: { textAlign: 'center' },
        },
      ]}
      data={data.map((item, index) => {
        return {
          data: [
            {
              info:
                item.CPF_COLABORADOR && item.CPF_COLABORADOR.length == 11
                  ? formatCPF(item.CPF_COLABORADOR)
                  : formatCNPJ(item.CPF_COLABORADOR),
              style: { textAlign: 'center' },
            },
            {
              info: item.NOME_COLABORADOR,
              style: { textAlign: 'center' },
            },

            {
              info: accontAndDigitMask(item.CONTA),
              style: { textAlign: 'center' },
            },
            {
              info:
                item.AGENDADO == 'S' ? (
                  chooseScheduledBadge(item.TRANSFERENCIA_STATUS)
                ) : (
                  <ContainerStatus background={'#D7D9DB'} color={'#D7D9DB'}>
                    Não agendado
                  </ContainerStatus>
                ),
              style: { textAlign: 'center' },
            },
            {
              info: 'R$' + formatMoney(item.VALOR),
              style: { textAlign: 'center' },
            },
          ],
        };
      })}
    />
  );
}

export default TableRechargePixAccount;
