import { Box, MenuItem, Paper, Switch, Tab, Tabs, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import api from "../../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Loading from "../../Loader/Loading";
import { alteraVencimentoEmprestimo, listarTaxaIDSemReload, updateTaxesV2 } from "../../../store/coban/actions";
import TextInputMask from 'react-masked-text';
import formatMoney from "../../../utils/formatMoneyBRL";
import ModalMensagemSemReload from "../../../views/Transaction/ModalMensagemSemReload";
import CustomContainer from "../../Custom/CustomContainer";
import { CreditCard, PriceChange } from "@mui/icons-material";
import { AccountBalance } from "@material-ui/icons";

export default function ModalAllFees({ modal, toggle, idCoban, profile, partner }) {
    const dispatch = useDispatch();
    const [periods, setPeriods] = useState([]);
    const [cortesias, setCortesias] = useState([]);
    const [tipoValor, setTipoValor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [canEdit, setCanEdit] = useState(false);
    const [modalMensagem, setModalMensagem] = useState({
        modal: false,
        sucesso: '',
        mensagem: ''
    });

    const { coban: { TAXAS }, taxaSucesso, taxaFail } = useSelector((state) => state.coban);

    const {
        getValues,
        setValue,
        register,
        watch,
    } = useForm({
        defaultValues: {
            TAXAS: {},
            CORTESIAS: {},
            DIA_VENCIMENTO: 0
        }
    });

    const tipoValorDict = (id, valor) => {
        const dict = [
            '',
            { placeholder: `R$ ${formatMoney(valor)}` },
            {
                options: {
                    unit: '',
                    suffixUnit: '%',
                },
                placeholder: `${formatMoney(valor)}%`
            }
        ];
        return dict[id]
    }


    useEffect(() => {
        if (modal && idCoban) {
            setCanEdit(false);
            setLoading(true);
            dispatch(listarTaxaIDSemReload(idCoban));
            api.get('tipo-periodo')
                .then(({ data }) => setPeriods(data.data));

            api.get(`coban/taxa/cortesia/${idCoban}`)
                .then(({ data }) => {
                    setCortesias(data.data);
                    setValue("CORTESIAS", Object.fromEntries(new Map(data.data.map((cortesia) => [cortesia.ID, { ...cortesia, active: cortesia.ATIVO === 'S' }])).entries()));
                    setLoading(false);
                });
            api.get('/coban/listar-tipo-valor')
                .then(({ data: { data } }) => setTipoValor(data.filter((tipo) => tipo.ID < 3)));
        }
    }, [modal]);

    function checkSuccess() {
        if (taxaSucesso && taxaSucesso.length > 0) {
            setModalMensagem({
                modal: true,
                mensagem: taxaSucesso[0].mensagem,
                sucesso: taxaSucesso[0].retorno === 'sucesso'
            });
        } else if (taxaFail && taxaFail.length > 0 && taxaFail[0].mensagem) {
            setModalMensagem({
                modal: true,
                mensagem: taxaFail[0].mensagem,
                sucesso: taxaFail[0].retorno === 'sucesso'
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        if (TAXAS && TAXAS.length > 0) {
            setValue("TAXAS", Object.fromEntries(new Map(TAXAS.map((fee) => [fee.ID, { ...fee }])).entries()));
        }
    }, [TAXAS]);

    function saveChanges() {
        setLoading(true);
        setCanEdit(false);
        dispatch(alteraVencimentoEmprestimo({ ID_COBAN: idCoban, DIA_VENCIMENTO: +getValues('DIA_VENCIMENTO') }));
        const updateCortesia = Object.values(getValues('CORTESIAS')).map((cortesia) => ({
            "ATIVO": cortesia.active !== undefined ? (cortesia.active ? 'S' : 'N') : cortesias.find((cort) => cort.ID === cortesia.ID).ATIVO,
            "PERIODO": cortesia.FK_TIPO_PERIODO,
            "ID_COBAN": idCoban,
            "QUANTIDADE": cortesia.QUANTIDADE,
            "ID_TIPO_TAXA": cortesia.FK_TIPO_TAXA
        }))
        api.post('coban/taxa/cortesia', updateCortesia);
        dispatch(updateTaxesV2(getValues('TAXAS'), () => checkSuccess()));
    }

    const components = [
        (<Box display={'flex'} flexDirection={'column'} p={'20px'}>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} style={{ gap: '1rem' }}>
                <Paper elevation={3} style={{ padding: '10px', width: '375px', marginBottom: '1rem' }}>
                    <h4>BOLETO</h4>
                    {cortesias && cortesias.length > 0 && TAXAS && TAXAS.length > 0 &&
                        TAXAS
                            .filter((fee) => [1, 7].includes(+fee.ID_TIPO_TAXA))
                            .map((fee, index) => {
                                const dict = { BOLETO: "RECEBIMENTO DE BOLETO" }
                                const id_tipo_valor = watch(`TAXAS.${fee.ID}.ID_TIPO_VALOR`);
                                if (id_tipo_valor === undefined) setValue(`TAXAS.${fee.ID}.ID_TIPO_VALOR`, fee.ID_TIPO_VALOR);
                                return (
                                    <>
                                        {index > 0 && <hr />}
                                        <Box sx={{ padding: '10px', marginBottom: '1rem' }}>
                                            <Box>
                                                <h4>{dict[fee.DESCRICAO] ? dict[fee.DESCRICAO] : fee.DESCRICAO}</h4>
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                    <TextInputMask
                                                        disabled={!canEdit}
                                                        {...register(`TAXAS.${fee.ID}.VALOR`)}
                                                        kind={'money'}
                                                        {...tipoValorDict(id_tipo_valor, fee.VALOR)}
                                                        onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                                        style={{
                                                            width: '50%',
                                                            height: '40px',
                                                            padding: '0 14px'
                                                        }}
                                                    />

                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Tipo Cobrança"
                                                        select
                                                        {...register(`TAXAS.${fee.ID}.ID_TIPO_VALOR`)}
                                                        value={id_tipo_valor}
                                                        disabled={!canEdit}
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        {tipoValor && tipoValor.length > 0 && tipoValor.map((valor) => (
                                                            <MenuItem key={valor.DESCRICAO} value={valor.ID}>
                                                                {valor.DESCRICAO}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>

                                            </Box>
                                            {
                                                cortesias.filter((cortesia) => cortesia.FK_TIPO_TAXA === fee.ID_TIPO_TAXA).map((cortesia) => {
                                                    const checkedState = watch(`CORTESIAS.${cortesia.ID}.active`);
                                                    if (checkedState === undefined) setValue(`CORTESIAS.${cortesia.ID}.active`, cortesia.ATIVO === 'S')
                                                    const recorrencia = watch(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`);
                                                    return (
                                                        <Box sx={{ marginTop: '1rem' }}>
                                                            <h4>Cortesia</h4>
                                                            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                                                <Switch
                                                                    color="primary"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.active`)}
                                                                    checked={checkedState}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.QUANTIDADE`)}
                                                                    label="Quantidade"
                                                                    style={{ width: '100px' }}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    label="Recorrência"
                                                                    select
                                                                    {...register(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`)}
                                                                    value={recorrencia}
                                                                    disabled={!canEdit}
                                                                    style={{ width: '140px' }}
                                                                >
                                                                    {periods && periods.length > 0 && periods.map((period) => (
                                                                        <MenuItem key={period.DESCRICAO} value={period.ID}>
                                                                            {period.DESCRICAO}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                )
                            })}
                </Paper>
                <Paper elevation={3} style={{ padding: '10px', width: '375px', marginBottom: '1rem' }}>
                    <h4>PIX</h4>
                    {cortesias && cortesias.length > 0 && TAXAS && TAXAS.length > 0 &&
                        TAXAS
                            .filter((fee) => [26, 27].includes(+fee.ID_TIPO_TAXA))
                            .map((fee, index) => {
                                const id_tipo_valor = watch(`TAXAS.${fee.ID}.ID_TIPO_VALOR`);
                                if (id_tipo_valor === undefined) setValue(`TAXAS.${fee.ID}.ID_TIPO_VALOR`, fee.ID_TIPO_VALOR)
                                return (
                                    <>
                                        {index > 0 && <hr />}
                                        <Box sx={{ padding: '10px', marginBottom: '1rem' }}>
                                            <Box>
                                                <h4>{fee.DESCRICAO}</h4>
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                    <TextInputMask
                                                        disabled={!canEdit}
                                                        {...register(`TAXAS.${fee.ID}.VALOR`)}
                                                        kind={'money'}
                                                        {...tipoValorDict(id_tipo_valor, fee.VALOR)}
                                                        onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                                        style={{
                                                            width: '50%',
                                                            height: '40px',
                                                            padding: '0 14px'
                                                        }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Tipo Cobrança"
                                                        select
                                                        {...register(`TAXAS.${fee.ID}.ID_TIPO_VALOR`)}
                                                        value={id_tipo_valor}
                                                        disabled={!canEdit}
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        {tipoValor && tipoValor.length > 0 && tipoValor.map((valor) => (
                                                            <MenuItem key={valor.DESCRICAO} value={valor.ID}>
                                                                {valor.DESCRICAO}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </Box>
                                            {
                                                cortesias.filter((cortesia) => cortesia.FK_TIPO_TAXA === fee.ID_TIPO_TAXA).map((cortesia) => {
                                                    const checkedState = watch(`CORTESIAS.${cortesia.ID}.active`);
                                                    if (checkedState === undefined) setValue(`CORTESIAS.${cortesia.ID}.active`, cortesia.ATIVO === 'S')
                                                    const recorrencia = watch(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`);
                                                    return (
                                                        <Box sx={{ marginTop: '1rem' }}>
                                                            <h4>Cortesia</h4>
                                                            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                                                <Switch
                                                                    color="primary"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.active`)}
                                                                    checked={checkedState}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.QUANTIDADE`)}
                                                                    label="Quantidade"
                                                                    style={{ width: '100px' }}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    label="Recorrência"
                                                                    select
                                                                    {...register(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`)}
                                                                    value={recorrencia}
                                                                    disabled={!canEdit}
                                                                    style={{ width: '140px' }}
                                                                >
                                                                    {periods && periods.length > 0 && periods.map((period) => (
                                                                        <MenuItem key={period.DESCRICAO} value={period.ID}>
                                                                            {period.DESCRICAO}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                )
                            })}
                </Paper>
                <Paper elevation={3} style={{ padding: '10px', width: '375px', marginBottom: '1rem' }}>
                    <h4>TED</h4>
                    {cortesias && cortesias.length > 0 && TAXAS && TAXAS.length > 0 &&
                        TAXAS
                            .filter((fee) => [3].includes(+fee.ID_TIPO_TAXA))
                            .map((fee, index) => {
                                const dict = { SAQUE: "TED" }
                                const id_tipo_valor = watch(`TAXAS.${fee.ID}.ID_TIPO_VALOR`);
                                if (id_tipo_valor === undefined) setValue(`TAXAS.${fee.ID}.ID_TIPO_VALOR`, fee.ID_TIPO_VALOR)
                                return (
                                    <>
                                        {index > 0 && <hr />}
                                        <Box sx={{ padding: '10px', marginBottom: '1rem' }}>
                                            <Box>
                                                <h4>{dict[fee.DESCRICAO] ? dict[fee.DESCRICAO] : fee.DESCRICAO}</h4>
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                    <TextInputMask
                                                        disabled={!canEdit}
                                                        {...register(`TAXAS.${fee.ID}.VALOR`)}
                                                        kind={'money'}
                                                        {...tipoValorDict(id_tipo_valor, fee.VALOR)}
                                                        onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                                        style={{
                                                            width: '50%',
                                                            height: '40px',
                                                            padding: '0 14px'
                                                        }}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        label="Tipo Cobrança"
                                                        select
                                                        {...register(`TAXAS.${fee.ID}.ID_TIPO_VALOR`)}
                                                        value={id_tipo_valor}
                                                        disabled={!canEdit}
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        {tipoValor && tipoValor.length > 0 && tipoValor.map((valor) => (
                                                            <MenuItem key={valor.DESCRICAO} value={valor.ID}>
                                                                {valor.DESCRICAO}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </Box>
                                            {
                                                cortesias.filter((cortesia) => cortesia.FK_TIPO_TAXA === fee.ID_TIPO_TAXA).map((cortesia) => {
                                                    const checkedState = watch(`CORTESIAS.${cortesia.ID}.active`);
                                                    if (checkedState === undefined) setValue(`CORTESIAS.${cortesia.ID}.active`, cortesia.ATIVO === 'S')
                                                    const recorrencia = watch(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`);
                                                    return (
                                                        <Box sx={{ marginTop: '1rem' }}>
                                                            <h4>Cortesia</h4>
                                                            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                                                <Switch
                                                                    color="primary"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.active`)}
                                                                    checked={checkedState}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    {...register(`CORTESIAS.${cortesia.ID}.QUANTIDADE`)}
                                                                    label="Quantidade"
                                                                    style={{ width: '100px' }}
                                                                    disabled={!canEdit}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    label="Recorrência"
                                                                    select
                                                                    {...register(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`)}
                                                                    value={recorrencia}
                                                                    disabled={!canEdit}
                                                                    style={{ width: '140px' }}
                                                                >
                                                                    {periods && periods.length > 0 && periods.map((period) => (
                                                                        <MenuItem key={period.DESCRICAO} value={period.ID}>
                                                                            {period.DESCRICAO}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                )
                            })}
                </Paper>
            </Box>
        </Box>),
        (<Box display={'flex'} flexDirection={'column'} p={'20px'}>
            <Box style={{ display: 'flex', flexFlow: 'row wrap', gap: '1rem' }}>
                <Paper elevation={3} style={{ padding: '10px', width: '375px', marginBottom: '1rem' }}>
                    <Box>
                        <h4>Dia do vencimento empréstimo:</h4>
                        <input
                            type="number"
                            min={1}
                            max={31}
                            step={1}
                            {...register(`DIA_VENCIMENTO`)}
                            onChange={({ target: { value } }) => {
                                value = +value > 31 ? 31 : +value;
                                value = +value < 1 ? 1 : +value;
                                setValue('DIA_VENCIMENTO', +value);
                            }}
                            style={{
                                width: '100%',
                                height: '40px',
                                padding: '0 14px',
                                marginBottom: '1rem'
                            }}
                            disabled={!canEdit}
                        />
                    </Box>
                    {TAXAS && TAXAS.length > 0 &&
                        TAXAS.filter((fee) => [14, 15, 18, 21, 22, 23].includes(+fee.ID_TIPO_TAXA)).map((fee) => {
                            if (+fee.ID_TIPO_TAXA === 14) {
                                return (
                                    <Box>
                                        <h4>Juros empréstimo:</h4>
                                        <TextInputMask
                                            kind={'money'}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            options={{
                                                unit: '',
                                                suffixUnit: '%',
                                            }}
                                            placeholder={formatMoney(fee.VALOR) + '% '}
                                            onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            } else if (+fee.ID_TIPO_TAXA === 15) {
                                return (
                                    <Box>
                                        <h4>Parcelamento máximo para empréstimo:</h4>
                                        <TextInputMask
                                            kind={'money'}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            options={{ precision: 0, unit: '' }}
                                            placeholder={fee.VALOR}
                                            onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            } else if (+fee.ID_TIPO_TAXA === 18) {
                                return (
                                    <Box>
                                        <h4>Valor máximo empréstimo:</h4>
                                        <TextInputMask
                                            kind={'money'}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            placeholder={formatMoney(fee.VALOR) + '% '}
                                            onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            } else if (+fee.ID_TIPO_TAXA === 21) {
                                return (
                                    <Box>
                                        <h4>Dia de corte:</h4>
                                        <input
                                            type="number"
                                            min={1}
                                            max={31}
                                            step={1}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            onChange={({ target: { value } }) => {
                                                value = +value > 31 ? 31 : +value;
                                                value = +value < 1 ? 1 : +value;
                                                setValue(`TAXAS.${fee.ID}.VALOR`, +value);
                                            }}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            } else if (+fee.ID_TIPO_TAXA === 22) {
                                return (
                                    <Box>
                                        <h4>Dia de reembolso:</h4>
                                        <input
                                            type="number"
                                            min={1}
                                            max={31}
                                            step={1}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            onChange={({ target: { value } }) => {
                                                value = +value > 31 ? 31 : +value;
                                                value = +value < 1 ? 1 : +value;
                                                setValue(`TAXAS.${fee.ID}.VALOR`, +value);
                                            }}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            } else if (+fee.ID_TIPO_TAXA === 23) {
                                return (
                                    <Box>
                                        <h4>Percentual liberado:</h4>
                                        <TextInputMask
                                            kind={'money'}
                                            {...register(`TAXAS.${fee.ID}.VALOR`)}
                                            options={{
                                                unit: '',
                                                suffixUnit: '%',
                                            }}
                                            placeholder={formatMoney(fee.VALOR) + '% '}
                                            onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                                padding: '0 14px',
                                                marginBottom: '1rem'
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </Box>
                                );
                            }
                        })
                    }
                </Paper>
            </Box>
        </Box>),
        (<Box display={'flex'} flexDirection={'column'} p={'20px'}>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} style={{ gap: '1rem' }}>
                {cortesias && cortesias.length > 0 && TAXAS && TAXAS.length > 0 &&
                    TAXAS
                        .filter((fee) => [24, 25].includes(+fee.ID_TIPO_TAXA))
                        .map((fee, index) => {
                            const dict = { BOLETO: "RECEBIMENTO DE BOLETO" }
                            const id_tipo_valor = watch(`TAXAS.${fee.ID}.ID_TIPO_VALOR`);
                            if (id_tipo_valor === undefined) setValue(`TAXAS.${fee.ID}.ID_TIPO_VALOR`, fee.ID_TIPO_VALOR);
                            return (
                                <Paper elevation={3} style={{ padding: '10px', width: '375px', marginBottom: '1rem' }}>
                                    <Box sx={{ padding: '10px', marginBottom: '1rem' }}>
                                        <Box>
                                            <h4>{dict[fee.DESCRICAO] ? dict[fee.DESCRICAO] : fee.DESCRICAO}</h4>
                                            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                <TextInputMask
                                                    disabled={!canEdit}
                                                    {...register(`TAXAS.${fee.ID}.VALOR`)}
                                                    kind={'money'}
                                                    {...tipoValorDict(id_tipo_valor, fee.VALOR)}
                                                    onChangeText={(value) => setValue(`TAXAS.${fee.ID}.VALOR`, value)}
                                                    style={{
                                                        width: '50%',
                                                        height: '40px',
                                                        padding: '0 14px'
                                                    }}
                                                />

                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    label="Tipo Cobrança"
                                                    select
                                                    {...register(`TAXAS.${fee.ID}.ID_TIPO_VALOR`)}
                                                    value={id_tipo_valor}
                                                    disabled={!canEdit}
                                                    style={{ minWidth: '150px' }}
                                                >
                                                    {tipoValor && tipoValor.length > 0 && tipoValor.map((valor) => (
                                                        <MenuItem key={valor.DESCRICAO} value={valor.ID}>
                                                            {valor.DESCRICAO}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Box>

                                        </Box>
                                        {
                                            cortesias.filter((cortesia) => cortesia.FK_TIPO_TAXA === fee.ID_TIPO_TAXA).map((cortesia) => {
                                                const checkedState = watch(`CORTESIAS.${cortesia.ID}.active`);
                                                if (checkedState === undefined) setValue(`CORTESIAS.${cortesia.ID}.active`, cortesia.ATIVO === 'S')
                                                const recorrencia = watch(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`);
                                                return (
                                                    <Box sx={{ marginTop: '1rem' }}>
                                                        <h4>Cortesia</h4>
                                                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>

                                                            <Switch
                                                                color="primary"
                                                                {...register(`CORTESIAS.${cortesia.ID}.active`)}
                                                                checked={checkedState}
                                                                disabled={!canEdit}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                {...register(`CORTESIAS.${cortesia.ID}.QUANTIDADE`)}
                                                                label="Quantidade"
                                                                style={{ width: '100px' }}
                                                                disabled={!canEdit}
                                                            />
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                label="Recorrência"
                                                                select
                                                                {...register(`CORTESIAS.${cortesia.ID}.FK_TIPO_PERIODO`)}
                                                                value={recorrencia}
                                                                disabled={!canEdit}
                                                                style={{ width: '140px' }}
                                                            >
                                                                {periods && periods.length > 0 && periods.map((period) => (
                                                                    <MenuItem key={period.DESCRICAO} value={period.ID}>
                                                                        {period.DESCRICAO}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </Paper>
                            )
                        })}
            </Box>
        </Box>)
    ]

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            style={{ width: '80%', maxWidth: '1300px' }}
        >
            <ModalHeader toggle={toggle}>
                <h2>Taxas</h2>
            </ModalHeader>
            <ModalBody>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} bgcolor={'rgb(242, 242, 242)'} p={'10px'}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box width='20rem'>
                            <h3>Perfil</h3>
                            <span>{profile}</span>
                        </Box>
                        <Box width='10rem'>
                            <h3>Parceiro</h3>
                            <span>{partner}</span>
                        </Box>
                    </Box>
                    <Box>
                        {
                            canEdit ? (
                                <>
                                    <Button
                                        color="success"
                                        style={{ marginRight: '3px', border: 'none' }}
                                        onClick={saveChanges}
                                        disabled={loading}
                                    >Salvar</Button>
                                    <Button
                                        color="danger"
                                        style={{ marginRight: '3px', border: 'none' }}
                                        onClick={() => {
                                            setCanEdit(false);
                                            toggle();
                                        }}
                                        disabled={loading}
                                    >Cancelar</Button>
                                </>
                            ) : (
                                <Button
                                    color="primary"
                                    style={{ marginRight: '3px', border: 'none' }}
                                    onClick={() => setCanEdit(!canEdit)}
                                    disabled={loading}
                                >Editar</Button>

                            )
                        }
                    </Box>
                </Box>
                {
                    loading ? (<Loading color='blue' />) : (
                        <>
                            <Tabs style={{ marginTop: '2rem' }} value={tab} onChange={(event, number) => setTab(number)} textColor="primary" indicatorColor="primary">
                                <Tab
                                    icon={<PriceChange />}
                                    sx={{
                                        bgcolor: 'white',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                    }}
                                    label="Transferências"
                                />
                                <Tab
                                    icon={<AccountBalance />}
                                    sx={{
                                        bgcolor: 'white',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                    }}
                                    label="Empréstimos"
                                />
                                <Tab
                                    icon={<CreditCard />}
                                    sx={{
                                        bgcolor: 'white',
                                        borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10,
                                    }}
                                    label="Cartões"
                                />
                            </Tabs>
                            {components[tab]}
                        </>
                    )
                }
            </ModalBody>
            <ModalMensagemSemReload
                modal={modalMensagem.modal}
                toggle={() => {
                    setModalMensagem({
                        modal: !modalMensagem.modal,
                        sucesso: '',
                        mensagem: ''
                    });
                    toggle();
                }}
                sucesso={modalMensagem.sucesso}
                mensagem={modalMensagem.mensagem}
            ></ModalMensagemSemReload>
        </Modal >
    );
}