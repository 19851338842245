import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Badge, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { alteraCobanUsuarioByUUID } from '../../store/user/actions';
import ModalMensagem from '../../views/Transaction/ModalMensagem';
import { addSTATE } from '../../store/globals';

const ModalInfoLoan = (props) => {
  const [newCoban, setNewCoban] = useState('');
  const [modalMsg, setModalMsg] = useState(false);
  const [modalSenha, setModalSenha] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');
  const [objNewCoban, setObjNewCoban] = useState({});

  const { modal, toggle, title } = props;

  function alterarNewCoban(e) {
    //Evitando mandar o selecione... como um valor
    if (e.target.value != 0) {
      const { value } = e.target;

      setNewCoban(value);
    }
  }

  function alterarCoban() {
    if (!!newCoban) {
      props.alteraCobanUsuarioByUUID(props.user.user.UUID, { NEW_COBAN: +newCoban }, (res) => {
        const { mensagem, retorno } = res;

        retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

        setMensagem(mensagem);
        setModalMsg(true);
      });
    }
  }

  return (
    <Modal isOpen={modal}>
      <ModalHeader toggle={toggle}>
        <h3>Alterar Perfil</h3>
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          wordBreak: 'break-word',
        }}
      >
        <div style={{ marginLeft: '15%' }}>
          <div className="form-group coban-select-container">
            {/* <label for="coban-select">Selecione o Correspondente Bancário:</label> */}
            <select
              onChange={(e) => {
                alterarNewCoban(e);
              }}
              style={{ width: '100%' }}
              id="coban-select"
              className="coban-select-2"
            >
              <option selected value={0} disabled={true}>
                Selecione o Correspondente Bancário:
              </option>
              {props.coban.list
                .filter((type) => type.PERFIL == '1')
                .map((a, index) => {
                  return <option value={a.ID}> {a.NOME}</option>;
                })}
            </select>
          </div>
          <div className="clearfix" style={{ marginTop: '15%' }}>
            <Button
              className="btn float-left"
              color="danger"
              size="sm"
              onClick={() => toggle()}
              style={{ width: '45%' }}
            >
              Voltar
            </Button>
            <Button
              className="btn float-right"
              color="success"
              size="sm"
              onClick={() => {
                alterarCoban();
              }}
              style={{ width: '45%' }}
            >
              Alterar
            </Button>
          </div>
        </div>

        <ModalMensagem
          modal={modalMsg}
          toggle={() => setModalMsg(!modalMsg)}
          mensagem={mensagem}
          sucesso={sucesso}
        />
      </ModalBody>
    </Modal>
  );
};

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

const mapStateToProps = ({ user, coban }) => {
  return { user, coban };
};

export default connect(mapStateToProps, { alteraCobanUsuarioByUUID, addSTATE })(ModalInfoLoan);
