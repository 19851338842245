import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table.jsx';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// reactstrap components
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';
import { Alert, Form, Row } from 'reactstrap';
import GenericButton from '../../components/Buttons/Button';
import CustomContainer from '../../components/Custom/CustomContainer.jsx';
import InputDate from '../../components/Filter/components/InputDate.js';
import InputSearch from '../../components/Filter/components/InputSearch.js';
import InputSelectAmountResults from '../../components/Filter/components/InputSelectAmountResults.js';
import InputStatus from '../../components/Filter/components/InputStatus.js';
import Filter from '../../components/Filter/Filter.js';
import * as Styled from '../../components/Filter/styles/Filter.style';
import Icon from '../../components/Icons/IconsButton';
import Loading from '../../components/Loader/Loading.jsx';
import StatusContainer from '../../components/StatusContainer/StatusContainer.js';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed.js';
import StatusContainerValue from '../../components/StatusContainer/StatusContainerValue.js';
import { getCardTransactions, getCardTransactionsID, saldoAporteCartao } from '../../store/transactions/actions.js';
import formatCNPJ from '../../utils/formatCNPJ.js';
import formatCPF from '../../utils/formatCPF';
import { formatMoney } from '../../utils/formatMoneyBRL.js';
import valueEmpty from '../../utils/valueEmpty.js';
import ModalInformations from './ModalInformations.jsx';
import InputWithSearch from '../../components/Filter/components/inputWithSearch.js';
import './styles.css';
import api from '../../service/api.js';
import isCpf from '../../utils/isCpf.js';

/* eslint-disable */
export default function CardSales() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');

  const [open, setOpen] = useState(true);
  const [select2, setSelect2] = useState(false);
  const [filter, setFilter] = useState({
    LIMIT: 10,
    SEARCH: '',
    PAGE: 1,
    STATUS: 0,
    ID_TIPO_PAGAMENTO: '',
    ESTABELECIMENTO: '',
    DATA_INICIAL: '',
    DATA_FINAL: moment().format('YYYY-MM-DD'),
    NUM_CARTAO: '',
  });

  useEffect(() => {
    dispatch(getCardTransactions(filter));
    dispatch(saldoAporteCartao());
  }, [filter.PAGE]);

  const {
    listCardSales: { PAGINATION, RETORNO },
    dadosCardID,
    DADOS_VENDAS_BOLETO,
    loading_transaction,
    aporte: {
      loading_aporte,
      cartao: { danger, pre_pago, pos_pago, aporte, limite_aporte },
    },
  } = useSelector((state) => state.transactions);

  useEffect(() => {
    api
      .post('cartao/transacao/listar', {
        LIMIT: 9999,
        SEARCH: '',
        PAGE: 1,
        STATUS: 0,
        ID_TIPO_PAGAMENTO: '',
        DATA_INICIAL: '',
        DATA_FINAL: moment().format('YYYY-MM-DD'),
        NUM_CARTAO: '',
      })
      .then((res) => setSelect2(res.data.RETORNO));
  }, []);

  let { PAGE } = filter;

  const clickInfo = (ID) => {
    dispatch(getCardTransactionsID(ID));
    setId(ID);
    setModal(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  const close = () => setModal(!modal);

  const tablePagination = async (type = 'atual', pageClick) => {
    switch (type) {
      case 'prev':
        PAGE = PAGINATION.prevPage;
        break;
      case 'next':
        PAGE = PAGINATION.nextPage;
        break;
      default:
        if (pageClick) PAGE = pageClick;
        break;
    }
    await setFilter({ ...filter, PAGE });
  };

  return (
    <div>
      <CustomContainer>
        {localStorage.getItem('nivelAcesso') == 1 && danger && (
          <div className={classes.root}>
            <Collapse in={open}>
              <Alert
                color="danger"
                variant="filled"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                ATENÇÃO O saldo disponível está abaixo do limite predefinido.{' '}
                <strong>Verifique a necessidade de novo aporte.</strong>
              </Alert>
            </Collapse>
          </div>
        )}

        <Filter>
          <Form className="form" onSubmit={(e) => e.preventDefault()}>
            <Styled.InputsContainer>
              <InputSearch
                width="30vw"
                name="SEARCH"
                value={filter.SEARCH}
                setValue={(value) => setFilter({ ...filter, SEARCH: value })}
              />
              {/*  <InputStatus
                width={'20vw'}
                inputName={'Produto'}
                setValue={(value) => setFilter({ ...filter, PRODUTO: value })}
                options={RETORNO && ['Todos', ...RETORNO.map((sale) => sale.PRODUTO)]}
                codeValue={RETORNO && ['', ...RETORNO.map((sale) => sale.PRODUTO)]}
              /> */}

              <InputStatus
                setValue={(value) => setFilter({ ...filter, STATUS: value })}
                options={['Todas', 'Aguardando', 'Aprovado', 'Cancelado', 'Vencido']}
                codeValue={['', 1, 2, 3, 4]}
              />
              <InputStatus
                inputName={'Forma Pagamento'}
                setValue={(value) => setFilter({ ...filter, ID_TIPO_PAGAMENTO: value })}
                options={['Todos', 'Cartão', 'Conta Digital']}
                codeValue={['', 1, 2]}
              />

              <InputDate
                label="Data Inicial"
                name="DATA_INICIAL"
                value={filter.DATA_INICIAL}
                setValue={(value) => setFilter({ ...filter, DATA_INICIAL: value })}
              />

              <InputDate
                label="Data Final"
                name="DATA_FINAL"
                value={filter.DATA_FINAL}
                setValue={(value) => setFilter({ ...filter, DATA_FINAL: value })}
              />

              <InputSearch
                width="15vw"
                name="ESTABELECIMENTO"
                placeholder="Estabelecimento"
                label="Estabelecimento"
                value={filter.ESTABELECIMENTO}
                setValue={(value) => setFilter({ ...filter, ESTABELECIMENTO: value })}
              />

              <InputWithSearch
                setValue={(value) => setFilter({ ...filter, NUM_CARTAO: value })}
                options={select2 && ['Todos', ...select2.map((card) => card.PAN_MASKED)]}
              />

              <div style={{ alignSelf: 'flex-end' }}>
                <GenericButton
                  className="mt-3"
                  size="sm"
                  type="submit"
                  onClick={() => {
                    dispatch(getCardTransactions(filter));
                    filter.PAGE = '1';
                  }}
                >
                  <Icon className="fas fa-search"></Icon>Buscar
                </GenericButton>
              </div>

              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="export-to-excel-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export"
              />
            </Styled.InputsContainer>
          </Form>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <InputSelectAmountResults
              options={['10', '25', '50', '100', '1000']}
              value={filter.LIMIT}
              setValue={(value) => setFilter({ ...filter, LIMIT: value })}
              width={'25rem'}
            />
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '30rem' }}>
              <h4>Saldo Pré-pago: R$ {formatMoney(pre_pago)}</h4>
              <h4>Saldo Pós-pago: R$ {formatMoney(pos_pago)}</h4>
            </div>
          </div>
        </Filter>

        <Row>
          <div className="col">
            {!PAGINATION ? (
              <h4 style={{ textAlign: 'center' }}>Carregando Transações</h4>
            ) : (
              <div>
                {loading_transaction ? (
                  <Loading color="#11cdef" />
                ) : (
                  <Table
                    headers={[
                      {
                        info: 'Datas/Hora',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'CPF/CNPJ',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Nome',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Cartão',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'Estabelecimento',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Produto',
                        style: { textAlign: 'left' },
                      },
                      {
                        info: 'Parceiro',
                        style: { textAlign: 'center' },
                      },

                      {
                        info: 'STATUS',
                        style: { textAlign: 'center' },
                      },
                      {
                        info: 'Valor',
                        style: { textAlign: 'center' },
                      },
                    ]}
                    data={
                      RETORNO &&
                      RETORNO.map((sale, index) => {
                        return {
                          data: [
                            {
                              info: sale.DATA_HORA,
                              style: { textAlign: 'center' },
                            },
                            {
                              info:
                                sale.CPFCNPJ && isCpf(sale.CPFCNPJ)
                                  ? formatCPF(sale.CPFCNPJ)
                                  : formatCNPJ(sale.CPFCNPJ),
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.NOME ? sale.NOME : '----',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.PAN_MASKED,
                              /* ? sale.ID_TIPO_PAGAMENTO == '1'
                                  ? 'Cartão'
                                  : 'Conta Digital'
                                : '----',
                              style: { textAlign: 'left' }, */
                            },
                            {
                              info: sale.ESTABELECIMENTO ? sale.ESTABELECIMENTO : '----',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.PRODUTO ? sale.PRODUTO : '----',
                              style: { textAlign: 'left' },
                            },
                            {
                              info: sale.PARCEIRO ? (
                                <StatusContainerTed code={sale.PARCEIRO} description={sale.PARCEIRO} />
                              ) : (
                                '----'
                              ),
                              style: { textAlign: 'center' },
                            },
                            {
                              info: valueEmpty(
                                sale.STATUS ? (
                                  <StatusContainer code={sale.STATUSTRANSACAO} description={sale.STATUS} />
                                ) : null,
                              ),
                              style: { textAlign: 'center' },
                            },
                            {
                              info: valueEmpty(
                                sale.VALOR_TOTAL ? (
                                  <StatusContainerValue code={sale.TIPO_TRANSACAO} description={sale.VALOR_TOTAL} />
                                ) : null,
                              ),
                              style: {
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                              },
                            },
                          ],
                          onClick: () => clickInfo(sale.ID),
                        };
                      })
                    }
                    pagination={PAGINATION}
                    previousPage={{ onClick: () => tablePagination('prev') }}
                    nowPage={{ PAGE, onClick: tablePagination }}
                    nextPage={{ onClick: () => tablePagination('next') }}
                  />
                )}
              </div>
            )}
          </div>
        </Row>
      </CustomContainer>
      <ModalInformations toggle={close} modal={modal} title={'Dados'} data={dadosCardID} ID={id} />
    </div>
  );
}
