import React, { useState, useEffect } from 'react';
import { Modal, Button, ModalHeader, ModalFooter, FormGroup, Form, Input, Label, Col, Row } from 'reactstrap';
import { getMenusConfigs, alteraMenus } from '../services/fetchsApi';
import ModalMensagem from '../../Transaction/ModalMensagem';

function ModalMenus(props) {
  const [menus, setMenus] = useState([]);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const { modal, toggle, id } = props;
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    setMenus([]);
    const fetchData = async () => {
      const data = await getMenusConfigs(id);
      setMenus(data.data.MENUS);
    };
    fetchData();
  }, [id]);
  async function changeMenus() {
    let data = { ID_COBAN: '', MENUS: [] };
    data.MENUS = menus;
    data.ID_COBAN = id;
    const res = await alteraMenus(data);
    setSucesso(!res.error);
    setMensagem(res.mensagem);
    setModalMensagem(true);
  }

  function mudarAtivado(e) {
    let arrayAuxiliar = [...menus];
    let index = arrayAuxiliar.map((object) => object.ID).indexOf(e.target.name);

    {
      e.target.id
        ? (arrayAuxiliar[e.target.name - 1].FILHOS[e.target.id].ATIVO =
            !arrayAuxiliar[e.target.name - 1].FILHOS[e.target.id].ATIVO)
        : (arrayAuxiliar[index].ATIVO = !arrayAuxiliar[index].ATIVO);
    }

    setMenus(arrayAuxiliar);
  }
  const menu = menus.map((menu, index) => (
    <FormGroup key={menu.ID_TAXA} style={{ marginBottom: '2px' }}>
      <Row
        style={{
          alignItems: 'center',
          backgroundColor: `white`,
          margin: 0,
          width: '100%',
          padding: '0 12px 0 12px ',
        }}
      >
        <Col>
          <h4>{menu.DESCRICAO} </h4>
        </Col>

        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Label check>
            <Input type="checkbox" defaultChecked={menu.ATIVO} onChange={mudarAtivado} name={menu.ID} /> Ativo
          </Label>
        </Col>
      </Row>
      {menu.FILHOS &&
        menu.FILHOS.length > 0 &&
        menu.FILHOS.map((filho, index) => (
          <Row
            style={{
              alignItems: 'center',
              backgroundColor: `white`,
              margin: 0,
              width: '100%',
              padding: '0 12px 0 12px ',
            }}
          >
            <Col>
              <h4> {filho.DESCRICAO} </h4>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Label check>
                <Input type="checkbox" defaultChecked={filho.ATIVO} onChange={mudarAtivado} name={menu.ID} id={index} />{' '}
                Ativo
              </Label>
            </Col>
          </Row>
        ))}
    </FormGroup>
  ));
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalMensagem
        modal={modalMensagem}
        toggle={() => {
          setModalMensagem(!modalMensagem);
        }}
        sucesso={sucesso}
        mensagem={mensagem}
      />
      <ModalHeader toggle={toggle}>
        <h3>Definir Parâmetros de Perfil</h3>
      </ModalHeader>
      <Form>{menu}</Form>
      <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
        <Button color="success" size="sm" block onClick={() => changeMenus()}>
          {' '}
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const styles = {
  inteiro: { width: '100%' },
  meio: { height: '50%' },
  inputStyle: { border: '1px solid rgb(206, 212, 218)' },
  myInput: {
    marginBottom: '10px',
    border: '1px solid rgb(206, 212, 218)',
    borderRadius: '0.25rem',
    color: '#8898aa',
  },
};

export default ModalMenus;
