import React from 'react';

import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Loading from '../../../../../../components/Loader/Loading';
import { MainContainer } from './styles';
import { formatDateBR } from '../../../../../../utils/dateKyc';
import { formatCNPJ, formatCPF, isCpf, valueEmpty } from '../../../../../../utils';
import StatusContainerValue from '../../../../../../components/StatusContainer/StatusContainerValue';

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};

export default function ModalTransferenciasPix({ toggle, open, loading, transaction }) {
  return (
    <>
      <Modal isOpen={open} toggle={toggle} size="lg" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={toggle}>
          <h2>Detalhes da transação</h2>
        </ModalHeader>
        {transaction ? (
          <ModalBody
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', wordBreak: 'break-word' }}
          >
            <Row style={styles.cheio}>
              <Col>
                <h5>Data</h5>
                <p>{formatDateBR(transaction.DATA)}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>CPF/CNPJ</h5>
                <p>
                  {transaction.CPFCNPJ
                    ? isCpf(transaction.CPFCNPJ)
                      ? formatCPF(transaction.CPFCNPJ)
                      : formatCNPJ(transaction.CPFCNPJ)
                    : null}
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>CPF/CNPJ da transferência</h5>
                <p>
                  {transaction.CPFCNPJ_DA_TRANSFERENCIA
                    ? isCpf(transaction.CPFCNPJ_DA_TRANSFERENCIA)
                      ? formatCPF(transaction.CPFCNPJ_DA_TRANSFERENCIA)
                      : formatCNPJ(transaction.CPFCNPJ_DA_TRANSFERENCIA)
                    : null}
                </p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Conta</h5>
                <p>{transaction.CONTA}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Conta da transferência</h5>
                <p>{transaction.CONTA_DA_TRANSFERENCIA ? transaction.CONTA_DA_TRANSFERENCIA : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Agencia pagador</h5>
                <p>{transaction.AGENCIA_PAGADOR ? transaction.AGENCIA_PAGADOR : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Agencia recebedor</h5>
                <p>{transaction.AGENCIA_RECEBEDOR ? transaction.AGENCIA_RECEBEDOR : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Nome pagador</h5>
                <p>{transaction.NOME_PAGADOR ? transaction.NOME_PAGADOR : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Nome recebedor</h5>
                <p>{transaction.NOME_RECEBEDOR ? transaction.NOME_RECEBEDOR : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>ISPB pagador</h5>
                <p>{transaction.ISPB_PAGADOR ? transaction.ISPB_PAGADOR : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>ISPB recebedor</h5>
                <p>{transaction.ISPB_RECEBEDOR ? transaction.ISPB_RECEBEDOR : '----'}</p>
              </Col>
            </Row>

            <Row style={styles.metade}>
              <Col>
                <h5>Sub modo pagamento</h5>
                <p>{transaction.SUB_MODOPAGAMENTO ? transaction.SUB_MODOPAGAMENTO : '----'}</p>
              </Col>
            </Row>
            <Row style={styles.metade}>
              <Col>
                <h5>Valor</h5>
                <p>
                  {valueEmpty(
                    transaction.VALOR ? (
                      <StatusContainerValue
                        padding={'0px'}
                        code={transaction.TIPO_TRANSACAO}
                        description={transaction.VALOR}
                      />
                    ) : null,
                  )}
                </p>
              </Col>
            </Row>
          </ModalBody>
        ) : (
          <Loading color="blue" />
        )}
      </Modal>
    </>
  );
}
